import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


import { Box, Flex, Heading, Text } from "@chakra-ui/react"
import { RichText } from "prismic-reactjs"

const ServicesOverview = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicServicesPage {
        edges {
          node {
            data {
              services_overview_sections {
                section_id {
                  text
                }
                services_overview_text {
                  raw
                }
                services_overview_title {
                  text
                }
                services_icon {
                  localFile {
                    publicURL
                  }
                }
                services_image_1 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED,
                        layout: FULL_WIDTH,
                        aspectRatio: 1.25
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                services_image_2 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED,
                        layout: FULL_WIDTH,
                        aspectRatio: 1.25
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
                services_image_3 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED,
                        layout: FULL_WIDTH,
                        aspectRatio: 1.25
                        transformOptions: {cropFocus: CENTER}
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const document = data.allPrismicServicesPage.edges[0].node.data.services_overview_sections

  return (
    <Box my="4">
      {document.map(service => {
        return (
          <>
            <Box w="100%" my="20" className="services-overview-box" id={service.section_id.text}>
            <Flex w="100%">
            <Box w="32px" mr="4" paddingTop="10px">
              <img src={service.services_icon.localFile.publicURL} alt="Icon" />
            </Box>
            <Box mb="4">
                <Heading as="h2" fontWeight="500"  fontSize="3rem">
                    {service.services_overview_title.text}
                </Heading>
            </Box>
            </Flex>
            <Box px="12">
              <Text>
                <RichText render={service.services_overview_text.raw} />
              </Text>
            </Box>
            <Box mb="4">
              <Flex flexWrap="wrap"
                justifyContent="center">
              {service.services_image_1.localFile && (
              <Box w={{sm: '100%', md: '30%'}} mx="4" mb="4">
                <GatsbyImage 
                        image={getImage(service.services_image_1.localFile)}
                        placeholder="blurred"
                        layout="fullWidth"
                        alt=""
                    />
                </Box>
                )
              } 
              {service.services_image_2.localFile && ( 
                <Box w={{sm: '100%', md: '30%'}} mx="4">
                <GatsbyImage 
                        image={getImage(service.services_image_2.localFile)}
                        placeholder="blurred"
                        layout="fullWidth"
                        alt=""
                    />
                </Box>
              )
      }
                {service.services_image_3.localFile && (
                <Box w={{sm: '100%', md: '30%'}}  mx="4">
                <GatsbyImage 
                        image={getImage(service.services_image_3.localFile)}
                        placeholder="blurred"
                        layout="fullWidth"
                        alt=""
                    />
                </Box>
                )
      }
              </Flex>
            </Box>
          </Box>
          </>
        )
      })}
    </Box>
  )
}

export default ServicesOverview
