import React from 'react'
import styled from '@emotion/styled'

const Logo = styled.svg `
  //width: ${props => props.width ? props.width : '500px' };
  width: 100%;
  height: auto;
  .cls-1 {
        fill: #e6eef2;
      }

      .cls-2 {
        fill: #e6eef2;
      }
`;

const IrisBackground = (props) => {
    return (
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 742.19995 742.09998" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M286.8,293l.1.1.3-.4-.1-.1Z"/>
      <path class="cls-1" d="M286.8,293l-42.7-36c-1.7,1.8-3.4,3.6-5,5.4l41.7,36.8A70.483,70.483,0,0,0,286.8,293Z"/>
      <path class="cls-1" d="M254,247.6c-1.9,1.6-3.7,3.3-5.5,5l38.6,40c1.9-2.2,3.7-4.4,5.4-6.8Z"/>
      <path class="cls-1" d="M280.4,299.7l.1.1.4-.4-.1-.1Z"/>
      <path class="cls-1" d="M302.8,268.5l.2.2c.2-.5.5-1,.7-1.5l-.2-.3C303.2,267.5,303,268,302.8,268.5Z"/>
      <path class="cls-1" d="M264.6,239.1c-2,1.5-3.9,3-5.9,4.6L293,285.4c1.7-2.4,3.4-4.9,4.9-7.5Z"/>
      <path class="cls-1" d="M292.6,285.9l.2.2a1.75578,1.75578,0,0,1,.4-.5l-.1-.2Z"/>
      <path class="cls-1" d="M235,267.2c-1.6,1.9-3.1,3.8-4.6,5.8l43.2,32.4c2.3-1.8,4.6-3.7,6.7-5.7Z"/>
      <path class="cls-1" d="M207.7,314.6q-1.35,3.45-2.4,6.9l13.6,4.6q4.5-.3,9-.9c1.4-.2,2.8-.4,4.1-.7h.1q2.1-.45,4.2-.9Z"/>
      <path class="cls-1" d="M236.3,323.6h0c1.8-.4,3.5-.9,5.3-1.4h0C239.8,322.7,238.1,323.1,236.3,323.6Z"/>
      <path class="cls-1" d="M213,301.9c-1,2.2-2,4.5-3,6.7l31.5,13.5a118.296,118.296,0,0,0,11.3-4.2Z"/>
      <path class="cls-1" d="M264.2,312l.1.1c.4-.2.8-.5,1.2-.7l-.1-.1C265,311.5,264.6,311.8,264.2,312Z"/>
      <path class="cls-1" d="M273,305.9l.1.1.7-.5-.1-.1Z"/>
      <path class="cls-1" d="M252.9,317.9h0c.9-.3,1.7-.7,2.4-1h-.1C254.4,317.2,253.6,317.6,252.9,317.9Z"/>
      <path class="cls-1" d="M226.8,278.1c-1.4,2-2.8,4.1-4.1,6.2l42.7,27c2.6-1.7,5.2-3.4,7.6-5.3Z"/>
      <path class="cls-1" d="M219.4,289.7c-1.2,2.1-2.4,4.3-3.5,6.5l39.3,20.7q4.65-2.25,9-4.8Z"/>
      <path class="cls-1" d="M297.9,277.8l.2.2c.2-.3.3-.6.5-.9l-.1-.2C298.2,277.3,298.1,277.6,297.9,277.8Z"/>
      <path class="cls-1" d="M482.1,495.7l-30.2-31.9a9.42812,9.42812,0,0,0-1,1l27,34.9C479.3,498.3,480.7,497,482.1,495.7Z"/>
      <path class="cls-1" d="M491.2,486.1l-31.7-28.4-1.2.8,29,31.9C488.7,488.9,490,487.5,491.2,486.1Z"/>
      <path class="cls-1" d="M513.6,453.6l-10.2-5.5h0a54.97681,54.97681,0,0,0-9.1-.8l16.6,11.2C511.9,456.9,512.8,455.2,513.6,453.6Z"/>
      <path class="cls-1" d="M499.6,475.8,469,452.4c-.6.3-1.2.5-1.8.8l28.9,27.1C497.3,478.8,498.4,477.3,499.6,475.8Z"/>
      <path class="cls-1" d="M472.2,504.6l-27-33.4c-.3.4-.6.8-1,1.3l23.3,35.7C469.1,507,470.6,505.8,472.2,504.6Z"/>
      <path class="cls-1" d="M461.5,512.7l-22.1-32.3c-.4.7-.7,1.4-1.1,2.2l18.2,33.5C458.3,514.9,459.9,513.8,461.5,512.7Z"/>
      <path class="cls-2" d="M307.2,256.9l.2.3c.3-1,.6-2,.9-3.1l-.2-.4C307.8,254.8,307.5,255.9,307.2,256.9Z"/>
      <path class="cls-2" d="M310.7,240.8l.2.5c.3-2.5.6-5,.7-7.5l-.2-.6A65.05823,65.05823,0,0,1,310.7,240.8Z"/>
      <path class="cls-1" d="M507,464.9l-25.6-16.5c-1.1.2-2.1.5-3.1.7l25.6,20.5C505,468.1,506,466.5,507,464.9Z"/>
      <path class="cls-2" d="M183.1,691.1l2.6,1.5L286.5,524c-2.2-1.1-4.3-2.3-6.4-3.5Z"/>
      <path class="cls-2" d="M568.8,57l-2.6-1.6L453.5,231.7c2.2,1.3,4.3,2.7,6.4,4.2Z"/>
      <path class="cls-2" d="M359.2,543.2l-7.6,198.6,3,.1,12-198.6h-1.9C362.9,543.3,361.1,543.2,359.2,543.2Z"/>
      <path class="cls-2" d="M709,524.8l1.3-2.8-188-87.3c-.9,2.4-1.8,4.7-2.8,7Z"/>
      <path class="cls-2" d="M593.2,73.8,590.8,72,464.1,238.9c2,1.5,4.1,3,6,4.6Z"/>
      <path class="cls-2" d="M720.2,497.4l1-2.9L526.4,422.4c-.7,2.4-1.5,4.8-2.3,7.2Z"/>
      <path class="cls-2" d="M196.4,368.8,0,372.1v3l196.3,1v-1.3C196.3,372.8,196.4,370.8,196.4,368.8Z"/>
      <path class="cls-2" d="M497.1,720.2l2.8-1.1-67-190.3c-2.3,1-4.6,2-6.9,2.9Z"/>
      <path class="cls-2" d="M504.1,446.8l10.2,5.5c-.9,1.7-2.9,5.3-3.8,7l-17.9-12h-.9a28.56556,28.56556,0,0,0-8.2.8l24.1,15.8c-1,1.6-3.3,5.1-4.3,6.6L477,449.6a30.11155,30.11155,0,0,0-6.6,2.2l29.9,23.1c-1.1,1.5-3.6,4.8-4.8,6.3L466,453.8a28.09883,28.09883,0,0,0-5.4,2.9l31.3,28.6c-1.3,1.4-4.1,4.5-5.4,5.9l-29.1-32a28.17516,28.17516,0,0,0-4.6,3.8l30,32c-1.4,1.4-4.4,4.2-5.9,5.5l-27-34.8a24.3833,24.3833,0,0,0-3.9,4.6L473,504c-1.5,1.3-4.7,3.8-6.3,5l-23.4-35.7a34.18242,34.18242,0,0,0-3.3,5.9l22,32.8c-1.7,1.4-4.7,3.5-6.3,4.7l-18.1-33a24.99549,24.99549,0,0,0-2.5,7.1h0l16.1,28.6c-1.7,1-5.5,3-7.2,4L433,498.5c-.6,3.4-.2,5.9-.2,9.5,0,2.5,1.1,4.5,1.4,6.9l5.1,10.9c-.8.4-2.6,1.3-3.4,1.7a60.92513,60.92513,0,0,0,13.4,22.2l75.1,159.5,2.8-1.3-66-148.5a58.9159,58.9159,0,0,0,15.6,6.9L551,695.8l2.6-1.5L484.9,568a60.287,60.287,0,0,0,14.7.3l76.7,112.1,2.5-1.7-72.7-111.4a60.22251,60.22251,0,0,0,13.2-4.4l81,100.2,2.4-1.9-78.1-101.1a62.20258,62.20258,0,0,0,10.9-8.2l87.4,92.1,2.2-2.1-85.6-94.2a60.98088,60.98088,0,0,0,7.7-11.3l96.6,86.7,2.1-2.3-96-90.1a59.30924,59.30924,0,0,0,3.8-13.7L663,600.4l1.9-2.4L554.2,509.7v-1.8a57.64207,57.64207,0,0,0-1.6-13.7l127.7,82.2,1.7-2.6-132.5-89.5a60.567,60.567,0,0,0-11.6-17.9l157.8,84.7,1.5-2.7L517.1,446.9c-.7,1.5-.7,1.6-1.4,3A58.43883,58.43883,0,0,0,504.1,446.8Z"/>
      <path class="cls-2" d="M381.2,742.1l3.1-.1-3.9-199.5c-2.4.2-4.9.4-7.4.5Z"/>
      <path class="cls-2" d="M675.3,158.5l-1.8-2.5L500.4,275.1c1.5,2,3,4.1,4.4,6.2Z"/>
      <path class="cls-2" d="M209.2,705.1l2.8,1.3L299.1,530c-2.3-1-4.5-2-6.7-3Z"/>
      <path class="cls-2" d="M322.1,739l3,.4,27.7-196.6c-2.5-.2-4.9-.4-7.3-.7Z"/>
      <path class="cls-2" d="M705.2,209.5l-1.4-2.7L514,296.9c1.2,2.2,2.3,4.5,3.4,6.8Z"/>
      <path class="cls-2" d="M616.2,92.4l-2.3-2L474.2,246.9l5.7,5.1Z"/>
      <path class="cls-2" d="M657.4,134.9l-2-2.3L492.4,265c1.7,1.9,3.3,3.9,4.8,5.9Z"/>
      <path class="cls-2" d="M264.3,726.6l2.9.8,58.3-188.8c-2.4-.6-4.8-1.2-7.1-1.9Z"/>
      <path class="cls-2" d="M293,734l3,.6,43.2-193.2c-2.4-.4-4.8-.8-7.2-1.3Z"/>
      <path class="cls-2" d="M410.8,740.2l3-.3-19.8-199.1c-2.4.4-4.9.8-7.3,1.1Z"/>
      <path class="cls-2" d="M691.3,183.4l-1.6-2.6L507.6,285.7c1.3,2.1,2.6,4.3,3.9,6.5Z"/>
      <path class="cls-2" d="M741.8,351.9l-.2-3-209.2,9.3c.2,2.5.4,5,.6,7.6Z"/>
      <path class="cls-2" d="M739.1,322.4l-.4-3-208,25.9c.4,2.5.8,5,1.1,7.5Z"/>
      <path class="cls-2" d="M489,261.1,637.7,112.8l-2.1-2.2-151.9,145C485.5,257.4,487.2,259.2,489,261.1Z"/>
      <path class="cls-2" d="M533.1,378.6l209,2.9.1-3-209-7.4v3.8C533.19995,376.2,533.2,377.4,533.1,378.6Z"/>
      <path class="cls-2" d="M734,293.3l-.7-3L527.8,332.7c.6,2.5,1.2,4.9,1.7,7.4Z"/>
      <path class="cls-2" d="M468.9,729.2l2.9-.8L420.3,533.9c-2.3.8-4.7,1.6-7.1,2.3Z"/>
      <path class="cls-2" d="M726.7,264.6l-.9-2.9L524.3,320.4c.8,2.4,1.6,4.8,2.3,7.3Z"/>
      <path class="cls-2" d="M236.3,716.9l2.9,1.1,73-183.1q-3.45-1.2-6.9-2.4Z"/>
      <path class="cls-2" d="M717,236.6l-1.1-2.8L519.6,308.5c1,2.3,1.9,4.7,2.8,7.1Z"/>
      <path class="cls-2" d="M440,735.8l3-.6-35.7-197.4c-2.4.6-4.8,1.2-7.2,1.7Z"/>
      <path class="cls-2" d="M308.1,253.8l-15.3-31.7c2.2-1.1,4.5-2.1,6.8-3l11.1,21.8c.3-2.5.6-5.1.7-7.7l-6.3-16.3c1.9-.7,6-2.1,6-2.1-.1-1.4-9-31.1-15.9-41.5l-16.7-40.5c-1.2,2.1-2.3,4.2-3.5,6.3l7,18.2a111.93154,111.93154,0,0,0-12.4-10.4c-.2.3-.5,1.1-.6,1-1,1.7-2.1,3.3-3.1,5l-6.2-12.2c-2.6-1.4-5.3-2.7-8-3.9l10.5,21.8c-2.6,4-5.4,7.9-8.2,11.8l-24.6-40.1c-2.1-.4-4.2-.7-6.4-.9l27.1,46.4c-2.5,3.4-5.1,6.7-7.8,10l-41-56.3c-1.8.2-3.5.4-5.2.7l42.2,60.6c-2.5,3-5,6-7.6,8.9L176,136c-1.4.6-2.9,1.2-4.3,1.8l54.8,66.7c-2.5,2.8-5.1,5.5-7.7,8.2l-64.9-64.3c-1.2.9-2.3,1.8-3.4,2.7l63.9,66.2c-2.6,2.6-5.3,5.3-8,7.8L136,165.6c-.9,1.1-1.8,2.3-2.7,3.4l68.3,60.3c-2.9,2.6-5.7,5.2-8.7,7.7l-70.1-50.1-1.8,4.2,66.9,50.2c-3.2,2.6-6.4,5.2-9.7,7.8l-62.9-37.8c-.3,1.6-.5,3.3-.7,5L173,253.2c-3.7,2.8-7.5,5.5-11.3,8.1l-47.3-23.5c.2,2,.5,3.9.8,5.9l40.9,21.5q-6.75,4.5-13.8,8.7l-21.1-8.5c1,2.4,2.1,4.8,3.2,7.1l11.7,5c-2,1.2-5,2.3-7,3.4.1.2,6.4,9,9.6,12.6l-19.1-6c-2.2,1.1-4.5,2.3-6.7,3.4l35.3,11.9a98.59423,98.59423,0,0,0,55.1,23.4s6.4.4,9.6.4c1.9,0,3.8-.1,5.7-.2L205,321.8c.7-2.3,1.6-4.7,2.4-6.9l28.6,8.9c1.8-.4,3.5-.9,5.3-1.4l-31.5-13.5c.9-2.3,1.9-4.5,3-6.7l39.8,16c.8-.4,1.6-.7,2.4-1.1l-39.3-20.7c1.1-2.2,2.3-4.4,3.5-6.5l44.7,22.3a8.3449,8.3449,0,0,0,1.2-.8l-42.7-27c1.3-2.1,2.7-4.1,4.1-6.2L273,306l.7-.5L230.5,273c1.5-2,3-3.9,4.6-5.8l45.3,32.5.4-.4-41.7-36.8c1.7-1.8,3.3-3.7,5-5.4l42.7,36,.3-.4-38.6-40c1.8-1.7,3.6-3.4,5.5-5L292.6,286l.4-.5-34.3-41.7c1.9-1.6,3.9-3.1,5.9-4.6L297.9,278c.2-.3.3-.6.5-.9l-28.9-41.5c2-1.4,4.1-2.8,6.2-4.1l27,37c.2-.5.5-1,.7-1.6l-22.6-38.6c2.2-1.3,4.3-2.5,6.5-3.6l19.8,32.2c.4-1.1.7-2.1,1-3.1Z"/>
      <path class="cls-2" d="M318.9,12c-.6,3.5-1.3,6.9-1.9,10.4l26.4,185.3c2.6-.3,5.3-.5,8-.7Z"/>
      <path class="cls-2" d="M19,488.4l1,2.9L205.9,431c-.8-2.3-1.6-4.6-2.3-6.9Z"/>
      <path class="cls-2" d="M134.7,657.2l2.4,1.9,126-150c-1.9-1.5-3.9-3-5.7-4.5Z"/>
      <path class="cls-2" d="M349.7.6l-3,.2L357,206.6c2.3-.1,4.7-.2,7-.2Z"/>
      <path class="cls-2" d="M4.9,431l.5,3,193.4-30.1c-.4-2.4-.8-4.8-1.1-7.2Z"/>
      <path class="cls-2" d="M431.3,4.9l-3-.5L393.1,208.8c2.5.4,5,.9,7.4,1.4Z"/>
      <path class="cls-2" d="M42.1,542.9l1.4,2.7,173.8-89.1c-1.2-2.1-2.3-4.3-3.4-6.5Z"/>
      <path class="cls-2" d="M460.3,10.9l-3-.7L405.9,211.5c2.5.6,4.9,1.3,7.3,2Z"/>
      <path class="cls-2" d="M92.2,616l2,2.3L242.1,490.4c-1.7-1.8-3.3-3.6-4.9-5.4Z"/>
      <path class="cls-2" d="M73.6,593l1.8,2.4L233,479.8c-1.5-1.9-3-3.8-4.4-5.8Z"/>
      <path class="cls-2" d="M56.9,568.6l1.7,2.6,166.2-102.7c-1.4-2-2.7-4.1-3.9-6.2Z"/>
      <path class="cls-2" d="M29.5,516.1l1.2,2.8,180.4-75c-1-2.2-1.9-4.4-2.9-6.7Z"/>
      <path class="cls-2" d="M372.4,0h-3l-1.1,206.4c2.1,0,4.3.1,6.4.3Z"/>
      <path class="cls-2" d="M401.9,1.3l-3-.2L380.2,207.2q3.75.3,7.5.9Z"/>
      <path class="cls-2" d="M112.6,637.4l2.2,2.1L252.2,500.1c-1.8-1.6-3.6-3.3-5.3-5Z"/>
      <path class="cls-2" d="M10.8,460l.7,3,190.2-45.4c-.6-2.3-1.2-4.7-1.7-7.1Z"/>
      <path class="cls-2" d="M543.2,42.3l-2.7-1.4L442.3,225.3c2.2,1.2,4.5,2.4,6.7,3.6Z"/>
      <path class="cls-2" d="M291.6,106.7c-1.1,2.3-2.2,4.7-3.3,7l29.6,99.4c2.4-.7,4.8-1.3,7.3-1.9Z"/>
      <path class="cls-2" d="M1.3,401.6l.3,3L197,390c-.2-2.4-.4-4.9-.5-7.3Z"/>
      <path class="cls-2" d="M735.8,440.3l.5-3L531.8,396.9c-.3,2.5-.7,5-1.1,7.5Z"/>
      <path class="cls-2" d="M306.2,70.6c-.9,2.7-1.9,5.4-2.9,8.1l27.1,131.2c2.4-.5,4.9-.9,7.4-1.3Z"/>
      <path class="cls-2" d="M158.2,675.1l2.5,1.7L274.4,517c-2.1-1.3-4.1-2.7-6.1-4.1Z"/>
      <path class="cls-2" d="M729.1,469.2l.8-2.9L529.6,409.8c-.5,2.5-1.1,4.9-1.7,7.4Z"/>
      <path class="cls-2" d="M740.1,411l.3-3L532.9,384c-.1,2.5-.3,5.1-.6,7.6Z"/>
      <path class="cls-2" d="M1.1,342.5l-.2,3,195.9,16.7c.2-2.4.4-4.9.7-7.3Z"/>
      <path class="cls-2" d="M516.4,29.6l-2.8-1.2L430.5,219.7c2.3,1,4.6,2,6.9,3.1Z"/>
      <path class="cls-2" d="M488.7,19.1l-2.9-.9L418.3,215.1c2.4.8,4.8,1.7,7.1,2.6Z"/>
      <path class="cls-2" d="M38.6,318c-3.1.8-6.4,1.5-9.5,2.3l169.3,28.1c.4-2.4.8-4.8,1.3-7.2Z"/>
      <path class="cls-2" d="M88.4,301.7c-2.5,1-5.1,2-7.7,3l120.5,30c.6-2.4,1.2-4.8,1.9-7.1Z"/>
    </g>
  </g>
</Logo>
    )
}
export default IrisBackground
