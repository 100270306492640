import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Box, Flex, Container, Heading } from "@chakra-ui/react"
import { RichText } from 'prismic-reactjs'

import Seo from "../components/seo"

import ServicesHero from "../components/ServicesPage/ServicesHero"
import ServicesOverview from "../components/ServicesPage/ServicesOverview"

const Services = ({ data }) => {
    const document = data.allPrismicServicesPage.edges[0].node.data
    const pageContent = {
      pageTitle: document.page_title.text,
      subTitle: document.page_subtitle.text,
      servicesParagraph: document.services_header_paragraph.raw,
      servicesGreyBox: document.services_header_grey_box.raw

    }
  return (
    <>
      <Layout annoucementDisplay="none">
        <Seo title="Iris Biomedical | ODM Services" />
        <ServicesHero 
          pageTitle={pageContent.pageTitle}
          pageSubTitle={pageContent.subTitle}
        />
        {/* <PageTitle
          title={pageContent.pageTitle}
          subtitle={pageContent.subTitle}
        /> */}
        <Box>
        <Container maxW="container.xl" zIndex="1000">
            <Flex flexWrap="wrap" mt={{sm: '25px', md: 0}}>
                <Box w={{sm: '100%', md: '50%'}} p="10">
                    <RichText render={pageContent.servicesParagraph} />
                </Box>
                <Box  w={{sm: '100%', md: '50%'}} bg="shadow.100" p={{sm: 10, md: 20}}
                    className="services-grey-box"
                >
                    <RichText render={pageContent.servicesGreyBox} />
                </Box>
            </Flex>
        </Container>
        </Box>
        {/* </ServicesHero> */}
        <Box mt={{sm: 40, md: 60}}>
        <Box>
        <Container maxW="container.xl">
            <Heading fontWeight="300" textAlign="center" mb="4">
                Services Overview
            </Heading>
            <hr />
            <Box mt="20">
              <ServicesOverview />
            </Box>

        </Container>
        </Box>
        </Box>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    allPrismicServicesPage {
      edges {
        node {
          data {
            page_title {
              text
            }
            page_subtitle {
              text
            }
            services_header_grey_box {
              raw
            }
            services_header_paragraph {
              raw
            }
          }
        }
      }
    }
  }
`

export default Services
